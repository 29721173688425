import React from 'react'

import Layout from 'src/components/Layout'

const IndexPage = () => (
  <Layout>
    <div style={{ backgroundColor: 'black', height: '50vh' }}> "</div>
  </Layout>
)

export default IndexPage
